import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
// @material-ui/core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

// core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-kit-react/views/components.js";

const useStyles = makeStyles(styles);

export default function SectionAboutAnterin(props) {
    const classes = useStyles();
    const { ...rest } = props;

    return (
      <div>
        <Header
            brand="Anterin.Id"
            rightLinks={<HeaderLinks />}
            fixed
            color="white"
            changeColorOnScroll={{
            height: 200,
            color: "white"
            }}
            {...rest}
        />
        
        <div className={classNames(classes.main)}>
        <div className={classes.sections}>
            <div style={{fontSize:14}} className={classes.container}>
            <br/>
        <br/>
        <br/>
        <br/>
        <p><strong>Anterin</strong>&nbsp;adalah aplikasi marketplace jasa transportasi dan pengantaran penumpang motor, mobil, pengiriman barang yang memberikan kebebasan pilihan kepada penggunanya secara online. Pelanggan bebas memilih siapa saja pengemudi sesuai dengan keinginan dan selera berdasarkan harga terbaik, jarak terdekat, jenis kendaraan, jenis kelamin bahkan penilaian pelanggan (personalized user experiences). Pengemudi mendapatkan kebebasan untuk menentukan tarif masing-masing. Anterin saat ini memiliki lebih dari 240.000 pengemudi yang bertumbuh secara organik, khususnya yang berbasis di JaBoDeTaBekKa, Bandung, Yogyakarta, Surabaya, dan Bali. Sebagai perusahaan berbasis layanan transportasi, kepuasan pengemudi sebagai mitra kerja (disebut Warga Anterin) dan pelanggan (disebut Teman Anterin) menjadi prioritas terdepan, karena Anterin percaya &ldquo;Love is not blind but love is&nbsp;BLUE&rdquo;&nbsp;.</p>
            <p>Berbagai informasi mengenai program Anterin:</p>
            <p>Instagram: @anterin.id<br />Facebook fanpage:&nbsp;<a href="http://www.facebook.com/anterin.id">www.facebook.com/anterin.id</a><br />Twitter: @anterin_id<br />Website:&nbsp;<a href="http://www.anterin.id/">www.anterin.id</a></p>
            <p><strong>VISI ANTERIN</strong></p>
            <p><em>M-Power the cities in collaboration.</em><br />Anterin percaya akan kekuatan pemberdayaan dan kekuatan kolaborasi. Dengan berkolaborasi, Anterin dan pihak terkait menjadi semakin kuat untuk mewujudkan kota masa depan yang pintar dan membanggakan.</p>
            <p><strong>MISI ANTERIN</strong></p>
            <p><em><strong>C-liberate people for their best future.</strong></em></p>
            <ul>
            <li>Memberikan kebebasan bagi seluruh pemangku kepentingan untuk meraih masa depan terbaik, melalui kolaborasi antara Anterin sebagai perusahaan marketplace, pengemudi (Warga Anterin), pelanggan (Teman Anterin), rekan bisnis, rekan media, dan pemerintah</li>
            <li>Memberikan kemandirian dan kemakmuran finansial bagi Warga Anterin</li>
            <li>Menyediakan transporting network sesuai selera dan kebutuhan Teman Anterin</li>
            <li>Memperkuat jaringan otot nasional untuk pemerintah dan rekan media melalui besutan produk berbasis inovasi oleh dari dan ke untuk Indonesia</li>
            <li>Melakukan kolaborasi inovatif dari keunggulan Anterin dengan rekan bisnis dan pengiklan</li>
            </ul>
            <br/>
            <br/>
            <br/>
                    </div>
                </div>
            </div>
        </div>

    )

}



