import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";

// core components
import { makeStyles } from "@material-ui/core/styles";
import  SectionHome  from "./SectionHome";



import styles from "assets/jss/material-kit-react/views/components.js";


const useStyles = makeStyles(styles);

export default function SectionHomeContent() {
    const classes = useStyles();
    
    return (
      <div>
        <SectionHome/>
        <div className={classNames(classes.main)}>
        <div className={classes.sections}>
            <div style={{fontSize:14}} className={classes.container}>
                <br/>
                <br/>
            <p>Dear Warga (driver) Anterin,</p>
                <p>Halo! Apabila akun Anterin Driver kamu telah aktif, selanjutnya, agar bisa menikmati kepastian penghasilan di Anterin, dan agar kamu bisa terus terima order pelanggan, pastikan kamu untuk Berlangganan terlebih dulu.</p>
                <p>Caranya, cukup dengan beli A-Token terus aktivasi paket Berlangganan sekarang juga.</p>
                <p>Berlangganan merupakan suatu sistem langganan (sewa aplikasi) yang berlaku di aplikasi Anterin yang dapat digunakan oleh Warga Anterin, di mana untuk mengaktifkan sistem ini wajib menggunakan A-Token.</p>
                <p>Motor dan Express (Pengiriman Barang)</p>
                <ul>
                <li>Berlangganan senilai Rp 150.000 mendapat masa aktif 14 hari gratis 16 hari.</li>
                <li>Berlangganan senilai Rp 75.000 mendapat masa aktif 7 hari gratis 7 hari.</li>
                </ul>
                <p>Mobil</p>
                <ul>
                <li>Berlangganan senilai Rp 150.000 mendapat masa aktif 7 hari gratis 23 hari.</li>
                <li>Berlangganan senilai Rp 75.000 mendapat masa aktif 4 hari gratis 10 hari.</li>
                </ul>
                <p>Setelah Top Up atau Berlangganan, Kamu akan menikmati:</p>
                <ol>
                <li>Bebas potongan</li>
                <li>Hasil narik 100% menjadi milik Kamu</li>
                <li>Kepastian penghasilan dari penumpang tetap dan hingga Rp4.5 juta rupiah per bulan dari JalinJalan, ikuti petunjuknya melalui tautan berikut:&nbsp;<a href="https://m.anterin.id/jalinjalan-warga-anterin/">https://m.anterin.id/jalinjalan-warga-anterin/</a></li>
                </ol>
                <p>Hubungi ANITA untuk Top Up dan Berlangganan Anterin:<br />WhatsApp: 08119222998 | Email: anita@anterin.id</p>
                <p>Untuk melayani pembelian token untuk Top Up/Berlangganan Anterin dan kemitraan menjadi agen, dealer dan distributor hubungi:</p>
                <p>Anterin Representative Update</p>
                <ol>
                <li>Jakarta Barat:<br />&ndash; Joe :+6287788340003<br />&ndash; Dadang: +6288290000123<br />&ndash; Alang : +6285782760044</li>
                <li>Jakarta Pusat:<br />&ndash; Joe : +6287788340003</li>
                <li>&nbsp;Jakarta Selatan:<br />&ndash; Alang : +6285782760044</li>
                <li>Jakarta Utara:<br />&ndash; Alang : +6285782760044<br />&ndash; Joe :+6287788340003<br />&ndash; Dadang: +6288290000123</li>
                <li>Jakarta Timur:<br />&ndash; Alang : +6285782760044</li>
                <li>Tangerang:<br />&ndash; Alang : +6285782760044<br />&ndash; Joe :+6287788340003<br />&ndash; Dadang: +6288290000123</li>
                <li>Depok:<br />&ndash; Alang : +6285782760044</li>
                <li>Bekasi:<br />&ndash; Alang : +6285782760044</li>
                <li>Bogor:<br />&ndash; Alang : +6285782760044<br />&ndash; Dadang: +6288290000123</li>
                <li>Semarang:<br />&ndash; Yoga : +6282323993369<br />(mencakup area Jawa Tengah Utara; Bumiayu, Brebes, Tegal, Pemalang, Pekalongan, Batang, Kendal, Salatiga, Kudus, Demak, Jepara, Pati, Juwana, Rembang dan Blora)</li>
                <li>Yogyakarta:<br />&ndash; Erick Febro : +6281390712712<br />(mencakup area Jawa Tengah Selatan; Purwokerto, Cilacap, Purbalingga, Banjarnegara, Klaten, Boyolali, Solo, Wonogiri</li>
                </ol>
                <ul>
                <li>Jakarta, Bogor, Depok, Tangerang, Bekasi</li>
                </ul>
                <ol>
                <li>Distributor: PT Sentral Sukses Makmur (Sesuma)<br />&ndash; Contact +6281287870877/Ferly<br />&ndash; Jl. Griya Setu Permai, Setu, Kab. Bekasi</li>
                <li>&nbsp;Distributor: Mplus Pulsa<br />&ndash; Contact +6282213222111/Aan<br />&ndash; Jl. Hasyim Ashari No 3, Pinang, Tangerang, Banten</li>
                <li>&nbsp;Distributor: WIB Reload<br />&ndash; Contact +628788000041/Ikhtisom<br />&ndash; Jl. Langsat No 9c Rt 7 Rw 5 Lagoa, Kec. Koja, Jakarta Utara 14270</li>
                <li>Distributor: Planet Voucher<br />&ndash; Contact +628985555959/Ci Lani<br />&ndash; Jl. Tanjung Duren Utara 4 no.2 Rt.06/03.Tanjung Duren, Jakarta Barat</li>
                <li>Distributor: Yurikom<br />&ndash; Contact +6282153100559/Jerry<br />&ndash; Duri Kepa Rt 07 Rw 10 Kel. Duri Kepa, Kec. Kebon Jeruk, Jakarta Barat</li>
                <li>Distributor: TV SHop<br />&ndash; Contact +6281219930871/Era<br />&ndash; Cluster Asia Blk B26, No 61, Banjar Wijaya, Tangerang Kota</li>
                <li>Distributor: Tiang Cun Hui<br />&ndash; Contact +628977053111/Ely<br />&ndash; Ruko Roxy Mas Blok C 2 no 37, Jakarta Pusat</li>
                <li>Distributor: Dimitri<br />&ndash; Contact +6288214695161/Dedi Sumantri<br />&ndash; Taman Permata Blok D No. 12 Cipondoh &ndash; Tangerang Kota</li>
                <li>PT. Auto Load<br />&ndash; Contact +62628176577777/Alimah<br />&ndash; Ruko Mega Grosir Cempaka Mas blok L no.45 sd 46, Jakarta Pusat</li>
                <li>Distributor: Premium Payment<br />&ndash; Contact +6281293958100/+628817496251<br />&ndash; Jl. Kebon Sayur I No 14, Rt 009 Rw 015 Bidaracina, Jatinegara, Jakarta Timur</li>
                </ol>
                <ul>
                <li>Jawa Barat&nbsp;</li>
                </ul>
                <ol>
                <li>Distributor: Rizal<br />&ndash; Contact: +6281546464747/Rizal<br />&ndash; Jl. Moch Yunus No 45 Pasir Kaliki CIcendo, Bandung, Jawa Barat</li>
                <li>Distributor: Zaki Alatas<br />&ndash; Contact: +6283825727273/Sony/Garasi Anterin Purwasuka<br />&ndash; Perum Hegarasih, Jl. Seruni No 9 Rt 02 Rw 08 Kel. Ciseureuh Kec. Purwakarta, Kab. Purwakarta, Jawa Barat<br />&ndash; Purwakarta, Subang, Karawang, Padalarang, Sukabumi, Tasik, Ciamis, Banjar, Pangandaran
                <p>Dealer: Giri<br />&ndash; Contact: +6281311600760/Giri<br />&ndash; Kampung Dang Deur Rt 08 Rw 04 Sukasari, Cisaat, Sukabumi</p>
                </li>
                </ol>
                <ul>
                <li>Yogyakarta &amp; Jawa Tengah</li>
                </ul>

                <ol>
                <li>Distributor: PT. Satu Kartu Indonesia (SKI)<br />&ndash; Contact: +628174118889/Andy</li>
                <li>Distributor: Sentosa Abadi<br />&ndash; Contact: +628994833000/Kukuh<br />&ndash; Jl. Brigjen Katamso 31, Yogyakarta (Karunia Cell) <br />
                Dealer: Nuryanto<br />&ndash; Contact: +6285643424373/Muhung<br />&ndash; Jl. Pandega Marta No 407, Yogyakarta (Counter Jarjos)
                </li>
                <li>Distributor: Setyo Wulan CP (Endang)<br />&ndash; Contact: +6285602417753/Qonita<br />&ndash; Alamat: Jl. Pahlawan Gg. Kriyan/Kebon Dalem II no. 1 (pendopo dalem belakang kantor PMI Potrobangsan) Magelang Utara, Jawa Tengah.<br />&ndash; Magelang, Temanggung, Wonosobo, Purworejo, Kebumen</li>
                <li>Distributor Dhyna Solo<br />&ndash; Contact: +6281334523266/Ery<br />&ndash; Jl. Adi Sucipto No 58, Colomadu, Karanganyar, Jawa Tengah<br />&ndash; Sragen, Boyolali, Wonogiri, Klaten, Sukoharjo, Surakarta, Kartasura</li>
                <li>Distributor Setyo Wulan CP Solo<br />&ndash; Contact +6285602417753/Qonita<br />&ndash; Jl. Kutilang VII, Cinderejo Kidul Rt 03 Rw 07, Gilingan, Banjarsari, Surakarta, Jawa Tengah<br />&ndash; Sragen, Boyolali, Wonogiri, Klaten, Sukoharjo, Surakarta, Kartasura</li>
                <li>Distributor Digital Masa Depan<br />&ndash; Contact: +628999333321/Hadi<br />&ndash; Hotel Savita Inn, Jl. Palagan Tentara Pelajar, Yogyakarta<br />&ndash; Purwokerto, Banyumas, Cilacap<br />19a. Dealer CV. BERKAH MULYA WAHYUDI (BMW)<br />&ndash; Contact: +6281944000013/Wahyudi<br />&ndash; Komplek Halal Foodcourt &amp; Resto Iskandaria, Jl. Bhayangkara No. 79, Penumping, Laweyan Surakarta 57141</li>
                <li>Distributor New Drift<br />&ndash; Contact: +62885225909889/Ratih<br />&ndash; Jl. RE. Martadinata Yogyakarta<br />&ndash; D.I Yogyakarta</li>
                <li>Distributor Mlati Putra Sembada<br />&ndash; Contact: +6282138373973/Aris Handriyanto<br />&ndash; Mlati Tegal RT.001 RW.018, Sendangadi, Mlati, Sleman, D.I Yogyakarta<br />&ndash; D.I Yogyakarta</li>
                </ol>
                <ul>
                <li>Jawa Tengah Utara</li>
                </ul>
                <ol>
                <li>Distributor: AnterinX11<br />&ndash; Contact: +6281390095246/Diah Yudhaningrum<br />&ndash; Perum Graha Perdana No 33, Banyumanik, Semarang, Jawa Tengah</li>
                <li>Distributor: Notaris Heriyanto Talchis<br />&ndash; Contact: +6285729976529/Pris<br />&ndash; Jl. Gatot Subrotono 9, Kalipancur, Ngaliyan, Semarang, Jawa Tengah</li>
                <li>Distributor: Kirana Anterin<br />&ndash; Contact: +6285643091570/Surya<br />&ndash; Ruko Panorama No 11, Jl. Payung Mas Raya, Pudak Payung, Semarang, Jawa Tengah</li>
                <li>Anggara Data Nusantara<br />&ndash; Contact: +6287733974077/Nanang Tri Anggoro<br />&ndash; Jl. Merbabu no 236 Rt 01 Rw 02 Kel. Kalidoro Kec. Pati, Kab. Pati<br />&ndash; Pati, Kudus</li>
                <li>Anterin Delapan Jaya<br />&ndash; Contact: +628170593811/Vani Wijaya<br />&ndash; Perum Ardimas Klipang, Jl. Pinang Blok D4 No 11 &ndash; 12, Sendang Mulyo, Tembalang, Semarang</li>
                </ol>
                <ul>
                <li>Jawa Timur</li>
                </ul>
                <ol>
                <li>Distributor: Andi Anterin<br />&ndash; Contact: +6281350268553/Andi Wijaya<br />&ndash; Jl. Akordion, Cluster Akordion Regency Blok A6, Malang, Jawa Timur</li>
                <li>Distributor: Anterin Didiek Surabaya<br />&ndash; Contact: +6287755276158/Didiek<br />&ndash; Lebak Arum IV No 27, Surabaya, Jawa Timur<br />Dealer Syamsudin<br />&ndash; Contact: +6285851130921/Syamsudin<br />&ndash; Jl. Joyo Tambaksari No 30 Rt 01 Rw 01, Merjosari, Lowokwaru, Blitar</li>
                </ol>
                <ul>
                <li>&nbsp;Sumatera</li>
                </ul>
                <ol>
                <li>Distributor Toedjoeh Tuah Kreasi<br />&ndash; Contact: +6281261120740/Anita Anterin Sumbagteng<br />&ndash; Jl. Pelangi RT 002 / RW 05 Kelurahan Sidomulyo Timur, Kecamatan Marpoyan Damai<br />&ndash; Pekanbaru; Medan; Jambi; Padang &amp; Batam</li>
                </ol>
                <ul>
                <li>Kalimantan</li>
                </ul>
                <ol>
                <li>Distributor: Anterin Max<br />&ndash; Contact: +6287811180042/Admin Anterin Max<br />&ndash; Jl. Putri Candramidi Gg. Sentosa NO 17, Pontianak (Maxphone), Kalimantan Barat<br />&ndash; All Kalimantan Barat</li>
                <li>Zahra Cell<br />&ndash; Contact: +6289507240724/Didik Hendrawan<br />&ndash; Jl. Prof. Dr. M Yamin No 39, Pontianak, Kalimantan Barat<br />&ndash; All Kalimantan Barat</li>
                </ol>
                <ul>
                <li>Sulawesi</li>
                </ul>
                <ol>
                <li>Distributor: PT. Fibra Mandiri<br />&ndash; Contact: +6281342268799/Burhanuddin; +6281340721988/Bahar; +6282350709268/Indah<br />&ndash; Jl. Lobak No 13, Wajo Baru Makasar<br />&ndash; All Sulawesi Selatan</li>
                </ol>
                <p>Masih bingung? Yuk tanya lebih lanjut ke ANITA:<br />WhatsApp: 08119222998 | Email: anita@anterin.id</p>
                <p>Atau klik video Youtube official Anterin berikut:
                    <a href="https://www.youtube.com/channel/UCXeG7fBfMePKgZiGonQMUpw"> www.youtube.com/anterin.id</a></p>
                <p>Salam Biru,</p>
                <br/>
                <br/>
                <br/>
                <br/>

                
                    </div>
                </div>
            </div>
        </div>

    )

}