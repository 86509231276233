import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
// @material-ui/core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

// core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-kit-react/views/components.js";

const useStyles = makeStyles(styles);

export default function SectionSKBerlangganan(props) {
    const classes = useStyles();
    const { ...rest } = props;

    return (
      <div>
        <Header
            brand="Anterin.Id"
            rightLinks={<HeaderLinks />}
            fixed
            color="white"
            changeColorOnScroll={{
            height: 200,
            color: "white"
            }}
            {...rest}
        />
        
        <div className={classNames(classes.main)}>
        <div className={classes.sections}>
            <div style={{fontSize:14}} className={classes.container}>
            <br/>
        <br/>
        <br/>
        <br/>
        <p><strong>DAFTAR ISTILAH</strong></p>
            <ol>
            <li>ANTERIN: Anterin adalah perusahaan yang bergerak dalam bidang transportasi online berbasis aplikasi pertama di dunia yang menggunakan metode lelang harga. Dengan metode ini, Anda bebas memilih siapa saja driver yang memberikan harga terbaik dan terdekat dengan Anda.</li>
            <li>WARGA: Pengemudi Anterin</li>
            <li>TEMAN: Pengguna/Penumpang Anterin</li>
            <li>BERLANGGANAN: Berlangganan adalah sistem kerja sama oleh Anterin dengan cara pembelian dan pengaktifan A-Token</li>
            <li>A-TOKEN (Anterin Token): Mata uang dalam aplikasi Anterin. 1 A-Token senilai 1 rupiah</li>
            </ol>
            <p><strong>SYARAT DAN KETENTUAN</strong></p>
            <ol>
                <li>Tentang Syarat dan Ketentuan: Silahkan baca syarat dan ketentuan ini dengan hati-hati. Ini adalah perjanjian elektronik, dan dengan mendaftar untuk menggunakan bagian manapun dari program, Anda setuju bahwa anda telah membaca, mengerti, menerima dan setuju dengan syarat dan ketentuan ini dan terikat olehnya. Jika anda tidak setuju untuk terikat oleh syarat dan ketentuan ini, Anda tidak diperbolehkan mengakses atau menggunakan bagian manapun dari program. Ketentuan penggunaan ini merupakan perjanjian hukum yang mengikat antara Anda sebagai Warga dan Anterin</li>
                <li>Berlangganan: Warga diberikan satu kali kesempatan di awal penggunaan aplikasi untuk mendapatkan penumpang (Teman). Untuk mendapatkan Teman selanjutnya, Warga diharuskan membeli A-Token dan aktivasi program berlangganan</li>
                <li>Masa Berlaku A-Token:<br />A-Token 300 ribu: Masa aktif 30 hari<br />A-Token 75 ribu: Masa aktif 7 hari</li>
                <li>Ketentuan Pembelian A-Token: Pembelian secara penuh harus dilakukan sebelum mengaktifkan akun Anterin Anda. Pembayaran dapat dilakukan dengan transfer bank, LinkAja, atau mekanisme lain yang ditentukan Anterin kemudian.
                    <ul>
                        <li>a. Pembayaran dengan LinkAja: Real time</li>
                        <li>b. Pembayaran dengan Bank Transfer: 1 x 24 jam, jika pembayaran dilakukan di hari yang sama sebelum pukul 16.00</li>
                    </ul>
                </li>
            <li>Biaya tidak dapat dikembalikan: A-Token yang telah dibeli tidak dapat dikembalikan atau di-uangkan.</li>
            <li>Update:
                <ul>
                    <li>Tambahan ketentuan dapat ditentukan secara terpisah (misalnya: durasi, diskon maupun manfaat dari A-Token)</li>
                    <li>Anterin berhak untuk merubah syarat dan ketentuan penggunaan A-Token sewaktu &ndash; waktu tanpa pemberitahuan terlebih dahulu</li>
                </ul>
            </li>
            <li>Sanksi: Jika ditemukan ketidaksesuaian atau kecurangan pada penggunaan program, maka Anterin berhak memberikan sanksi kepada pihak yang terlibat.</li>
            <li>Batasan Penggunaan: Hak yang diberikan ke pada Anda lewat syarat dan ketentuan ini tetap berlaku. Anda dapat menggunakan layanan hanya untuk tujuan pribadi, non-komersial, Anda tidak boleh memodifikasi produk turunan apapun yang ada di layanan.9. Kontak: Jika Anda memerlukan bantuan, jangan ragu untuk menghubungi kami di:<br />Email:&nbsp;<a href="mailto:support@anterin.id">anita@anterin.id</a><br />Telepon: 021 22715555<br />Whatsapp: 08119222998</li>
            </ol>
            <br/>
            <br/>
            <br/>
                    </div>
                </div>
            </div>
        </div>

    )

}



