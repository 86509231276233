import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
// @material-ui/core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

// core components
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import styles from "assets/jss/material-kit-react/views/components.js";

// const useStyles = makeStyles(styles);
const useStyles = makeStyles(styles, theme => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
  }));
  
export default function SectionAtokenLinkAja(props) {
    const classes = useStyles();
    const { ...rest } = props;
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
      <div>
        <Header
            brand="Anterin.Id"
            rightLinks={<HeaderLinks />}
            fixed
            color="white"
            changeColorOnScroll={{
            height: 200,
            color: "white"
            }}
            {...rest}
        />
        
        <div className={classNames(classes.main)}>
        <div className={classes.sections}>
            <div style={{fontSize:14}} className={classes.container}>
            <br/>
        <br/>
        <br/>
        <br/>
        <div className={classes.root}>
            <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                >
                <Typography className={classes.heading}>Bagaimana Cara Mendaftar LinkAja? </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                <Typography>
                <p><strong>Registrasi &amp; Aktivasi Dari Aplikasi LinkAja*</strong></p>
                <ol>
                <li><strong>Unduh aplikasi LinkAja yang tersedia di Play Store / App Store melalui&nbsp;</strong><strong>ponsel yang akan digunakan pada program Berlangganan (Subscription).</strong></li>
                <li><strong>Daftarkan data diri melalui aplikasi dan lengkapi formulir pendaftaran online.</strong></li>
                <li><strong>Tentukan 6-digit-PIN LinkAja.</strong></li>
                <li><strong>Bapak/Ibu sudah bisa menikmati layanan LinkAja.</strong></li>
                </ol>
                <ul>
                <li><strong>Registrasi &amp; aktivasi dari telepon (UMB *800#)*</strong></li>
                <li><strong>Khusus untuk pengguna kartu Telkomsel, LinkAja bisa di aktivasi dengan menghubungi *800*88*6-#PIN Telkomsel Bapak/Ibu.</strong></li>
                <li><strong>Bapak/Ibu perlu membuat #PIN sendiri dan jangan memberikan #PIN&nbsp;</strong><strong>kepada siapapun. Pihak Telkomsel, LinkAja dan Anterin tidak akan pernah dan&nbsp;</strong><strong>tidak boleh meminta #PIN.</strong></li>
                </ul>
                </Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
                >
                <Typography className={classes.heading}>Bagaimana Cara Cek Saldo LinkAja?</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                <Typography>
                <ol>
                    <li><strong>Hubungi *800# dari ponsel yang menggunakan Telkomsel</strong></li>
                    <li><strong>Pilih menu cek / isi LinkAja.</strong></li>
                    <li><strong>Pilih menu cek saldo.</strong></li>
                    <li><strong>Masukkan 6 digit PIN LinkAja.</strong></li>
                    <li><strong>Bapak/Ibu akan menerima informasi saldo LinkAja.</strong></li>
                </ol>
                <ul>
                    <li><strong>Atau, bisa juga cek saldo melalui SMS dengan cara: Ketik SAL (spasi) PIN &rarr; kirim SMS ke 2828.</strong></li>
                </ul>
                </Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
                >
                <Typography className={classes.heading}>Bagaimana Cara Isi Ulang/Top-Up LinkAja? </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                <Typography>
                <ol>
                <li>
                <p><strong>Top Up Lewat GraPARI</strong></p>
                <p>a. Kunjungi GraPARI terdekat.<br />b. Hampiri petugas kasir dan infokan nomer Telkomsel yang akan di isi saldonya.<br />c. Sampaikan nominal saldo yang diinginkan dan serahkan uang tunai kepada petugas kasir.<br />d. Kemudian, ikuti instruksi kasir untuk menyelesaikan transaksi.</p>
                <p><strong>Top Up Lewat Bank Transfer</strong></p>
                <p>a. Masukkan kartu ATM dan PIN akun bank.<br />b. Pilih menu transfer.<br />c. Masukkan kode bank untuk LinkAja: 911.<br />d. Masukkan nomer rekening, yaitu nomer telepon yang terdaftar pada LinkAja (contoh: 08112345xxx).<br />e. Ketik nominal jumlah uang yang diinginkan.<br />f. Ikuti instruksi yang muncul untuk menyelesaikan transaksi.</p>
                <p><strong>Top Up Lewat Mobile Banking &amp; Internet Banking</strong></p>
                <p>a. Login atau masuk ke aplikasi&nbsp;<em>mobile banking.</em><br />b. Pilih menu transfer ke rekening bank lain.<br />c. Pilih tujuan transfer ke LinkAja dengan kode bank: 911.<br />d. Masukkan nomer ponsel yang terdaftar di Linkaja (contoh: 08112345xxx).<br />e. Ketik nominal jumlah uang yang diinginkan.<br />f. Ikuti instruksi yang muncul untuk menyelesaikan transaksi.</p>
                <p><strong>Top Up Lewat Virtual Account, Dilakukan Dengan Virtual Account BCA</strong></p>
                <p><strong>ATM BCA</strong></p>
                <p>a. Masukkan kartu ATM dan PIN akun bank.<br />b. Pilih menu transaksi lainnya.<br />c. Pilih menu transfer ke rekening BCA&nbsp;<em>virtual account</em>.<br />d. Masukkan nomer BCA&nbsp;<em>virtual account&nbsp;</em>dengan memasukkan kode perusahaan LinkAja 09110 diikuti nomor telepon yang terdaftar pada LinkAja (contoh: 08112345xxx).<br />e. Ketik nominal jumlah uang yang diinginkan.<br />f. Layar ATM akan menampilkan detail transaksi. Jika data sudah benar, pilih &ldquo;YA&rdquo; dan ikuti instruksi yang muncul untuk menyelesaikan transaksi.</p>
                <p><strong>m &ndash; BCA</strong></p>
                <p>a.&nbsp;<em>Log in</em>&nbsp;atau masuk ke aplikasi&nbsp;<em>mobile banking</em>.<br />b. Pilih m &ndash; BCA dan masukkan kode akses.<br />c. Pilih menu m &ndash; transfer lanjutkan ke menu BCA&nbsp;<em>virtual account</em>.<br />d. Masukkan nomer BCA&nbsp;<em>virtual account</em>&nbsp;dengan memasukkan kode perusahaan LinkAja 09110 diikuti nomer telepon yang terdaftar pada LinkAja (contoh: 08112345xxx).<br />e. Ketik nominal jumlah uang yang diinginkan dan masukkan PIN BCA.<br />f. Aplikasi m &ndash; BCA akan menampilkan detail transaksi. Jika data sudah benar, pilih &ldquo;OK&rdquo; dan ikuti instruksi yang muncul untuk menyelesaikan transaksi.</p>
                <p><strong>KlikBCA</strong></p>
                <p>a. Login atau masuk ke aplikasi KlikBCA individual.<br />b. Masukkan user ID dan PIN.<br />c. Pilih menu transfer dana lanjutkan ke menu transfer ke BCA&nbsp;<em>virtual account.</em><br />d. Masukkan nomor BCA&nbsp;<em>virtual account</em>&nbsp;dengan memasukkan kode perusahaan LinkAja 09110 diikuti nomer telepon yang terdaftar pada LinkAja (contoh: 08112345xxx).<br />e. Ketik nominal jumlah uang yang diinginkan dan masukkan PIN BCA.<br />f. Aplikasi KlikBCA akan menampilkan detail transaksi. Jika data sudah benar, pilih &ldquo;Kirim&rdquo; dan ikuti instruksi yang muncul untuk menyelesaikan transaksi.</p>
                <p><strong>Top Up Lewat Virtual Account, Dilakukan Dengan Toko Retail (Indomaret atau Alfamart).</strong></p>
                <p>a. Tekan *800# untuk masuk ke menu LinkAja (khusus pengguna Telkomsel).<br />b. Pilih menu nomer 3, untuk &ldquo;Buat Token&rdquo;.<br />c. Masukkan nomer PIN dan tunggu SMS dari 2828.<br />d. Berikan nomer Token dan nominal saldo LinkAja yang diinginkan kepada kasir.<br />e. Kasir akan memasukkan informasi sesuai permintaan Bapak/Ibu.<br />f. Bapak/Ibu akan menerima struk bukti isi saldo dan SMS konfirmasi dari 2828.<br />g. Bagi pengguna non Telkomsel, top up dapat dilakukan dengan cara:</p>
                <ul>
                <li>Buka aplikasi LinkAja.</li>
                <li>Klik&nbsp;<em>profile picture</em>.</li>
                <li>Pilih Token pada bagian kanan atas&nbsp;<em>QR code</em>.</li>
                <li>Masukkan PIN LinkAja</li>
                <li>Bapak/Ibu akan mendapatkan SMS dari LinkAja berupa 10&nbsp;<em>digit</em>&nbsp;Token.</li>
                <li>Tunjukkan nomor Token pada kasir / tunjukkan&nbsp;<em>barcode</em>&nbsp;di dalam aplikasi LinkAja untuk di&nbsp;<em>scan</em>&nbsp;oleh kasir.</li>
                </ul>
                </li>
                </ol>
                </Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
                >
                <Typography className={classes.heading}>Ketentuan Penggunaan</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                <Typography>
             
                <ol>
                <li>LinkAja bisa digunakan oleh seluruh Pelanggan Telkomsel paska bayar (kartu Halo) maupun prabayar (simPATI, Kartu As dan LOOP).</li>
                <li>Pelanggan tidak perlu memiliki rekening bank untuk menjadi Pelanggan LinkAja. Pelanggan hanya perlu memiliki nomor Telkomsel dan melakukan pendaftaran dengan menghubungi&nbsp;<strong>*800*88*6-digit-PIN#</strong></li>
                <li>Pelanggan bisa ajukan blokir akun LinkAja dan melaporkan kehilangan kartu ke call center Telkomsel 188 atau ke Grapari terdekat. Pelanggan juga bisa datang ke Grapari untuk mengajukan penggantian kartu SIM dan menginformasikan kepemilikan akun LinkAja. Akun LinkAja akan kembali aktif dengan nomor Telkomsel yang sama.</li>
                <li>Jika Pelanggan memilih nomor baru / ganti nomor, maka saldo LinkAja dari kartu sebelumnya dapat dipindahkan ke nomor yang baru dengan cara menutup akun LinkAja di nomor sebelumnya dan memasukkan ke nomor yang baru di GraPARI terdekat.</li>
                </ol>
                

                </Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel5bh-header"
                >
                <Typography className={classes.heading}>Panduan Pembelian A-Token melalui LinkAja</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                <Typography>
                    <p>Warga yang belum memiliki A-Token aktif, akan muncul notifikasi aktifkan layanan di aplikasi driver-nya.</p>
                    <p><strong>Warga pilih metode pembayaran LinkAja dari aplikasi Anterin driver.</strong></p>
                </Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            </div>
            <br/>
            <br/>
            <br/>
                    </div>
                </div>
            </div>
        </div>

    )

}



