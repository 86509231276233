import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

// core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-kit-react/views/components.js";

const useStyles = makeStyles(styles);

export default function SectionApoin(props) {
    const classes = useStyles();
    const { ...rest } = props;

    return (
      <div>
        <Header
            brand="Anterin.Id"
            rightLinks={<HeaderLinks />}
            fixed
            color="white"
            changeColorOnScroll={{
            height: 200,
            color: "white"
            }}
            {...rest}
        />
        
        <div className={classNames(classes.main)}>
        <div className={classes.sections}>
            <div style={{fontSize:14}} className={classes.container}>
            <br/>
        <br/>
        <br/>
        <br/>
            <h3 style={{fontWeight:'bold', paddingTop:0}}>Syarat dan Ketentuan A-Poin</h3>
            <p><strong>Ketentuan Program :</strong></p>
                <ol>
                <li>Anterin berhak melakukan diskualifikasi pemenang atas temuan kecurangan</li>
                <li>Pemenang dianggap tidak valid apabila memiliki lebih dari 1 akun yang terdaftar di aplikasi dengan kesamaan identitas</li>
                <li>Anterin berhak&nbsp;setiap saat dan dari waktu ke waktu, tanpa pemberitahuan, untuk menambah, menghapus, mengubah atau menghentikan program. Contoh, Anterin dapat mengubah manfaat, tingkat kredit hadiah, afiliasi program, atau penawaran penukaran setiap saat tanpa pemberitahuan. Pengguna bertanggung jawab untuk mengakses situs ini untuk mendapatkan informasi tentang hak dan tanggung jawab pengguna dalam program ini</li>
                </ol>
                <p><strong>Ketentuan Penukaran Poin :</strong></p>
                <p>Setiap pengguna baik Warga dan Teman Anterin, memiliki hak untuk menukarkan Poin dengan hadiah. Program penukaran poin dengan hadiah, yaitu:</p>
                <ol>
                <li>Penukaran Poin dengan Uang (khusus Warga Anterin &amp; Mitra)</li>
                </ol>
                <p><strong>Penukaran Poin dengan Uang LinkAja (Warga &amp; Mitra):</strong></p>
                <p>1. Penukaran poin dengan uang bisa dilakukan oleh warga anterin bila telah memenuhi syarat,<br />yaitu:</p>
                <ul>
                <li>Memenuhi total minimal poin (5000 A-Poin)</li>
                <li>A-Poin di bawah 5000 akan dilakukan proses tukar poin (redeem) oleh sistem dengan diberikan masa aktif, yang akan diproses 7 hari kerja setelah periode redeem berakhir</li>
                <li>Penukaran A-Poin dengan uang hanya bisa dilakukan oleh Warga/Mitra Anterin</li>
                </ul>
                <p>2. Proses redeem&nbsp; akan dilakukan selama 7 hari kerja setelah periode penukaran berakhir.</p>
                <p>3. Poin yang ditukarkan dengan uang adalah seluruh A-Poin yang dimiliki pada saat penukaran sehingga setelah penukaran selesai saldo A-Poin yang dimiliki menjadi 0 (nol)</p>
                <p>4. Pengguna tinggal klik Tukar Poin dengan uang pada aplikasi sesuai periode penukaran yang telah ditentukan.</p>
                <ul>
                <li>Tombol tukar poin ke uang hanya bisa dilakukan untuk pengguna yang akun anterinnya telah terhubung dengan LinkAja</li>
                <li>Jika akun anterin pengguna belum terhubung dengan akun LinkAja, maka ada<br />informasi petunjuk untuk menghubungkan akunnya dengan LinkAja</li>
                <li>Setelah Warga/Mitra menukarkan A-Poinnya pada periode Februari 2020,&nbsp; seluruh poin milik pengguna akan di-<em>reset</em>&nbsp;menjadi 0 (nol)</li>
                </ul>
                <br/>
                <br/>
                <br/>
                <br/>
                    </div>
                </div>
            </div>
        </div>

    )

}