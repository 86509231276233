import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
// @material-ui/core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

// core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-kit-react/views/components.js";

const useStyles = makeStyles(styles);

export default function SectionAnita(props) {
    const classes = useStyles();
    const { ...rest } = props;

    return (
      <div>
        <Header
            brand="Anterin.Id"
            rightLinks={<HeaderLinks />}
            fixed
            color="white"
            changeColorOnScroll={{
            height: 200,
            color: "white"
            }}
            {...rest}
        />
        
        <div className={classNames(classes.main)}>
        <div className={classes.sections}>
            <div style={{fontSize:14}} className={classes.container}>
            <br/>
        <br/>
        <br/>
        <br/>
        <div>
            <img style={{width:'100%'}} src={require("assets/img/anita.jpeg")} alt=""/>  
        </div>
            <br/>
            <br/>
            <br/>
                    </div>
                </div>
            </div>
        </div>

    )

}



