import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.8.0";

// pages for this product
import LandingPage from "views/LandingPage/LandingPage.js";
import ProfilePage from "views/ProfilePage/ProfilePage.js";
import LoginPage from "views/LoginPage/LoginPage.js";
import Guide from "views/Components/Guide.js"
import Cashback from "views/Components/Section/sectionLinkAja";
import SectionHomeContent from "views/Components/Section/sectionHomeContent";
import Footer from "components/Footer/Footer";
import SectionApoin from "views/Components/Section/sectionApoin";
import sectionBerlangganan from "views/Components/Section/anterinHandbook/sectionBerlangganan";
import SectionSKBerlangganan from "views/Components/Section/anterinHandbook/s&kberlanngganan";
import sectionAnterinXlinkaja from "views/Components/Section/sectionPromo/sectionAnterinXlinkaja";
import SectionAnita from "views/Components/Section/sectionAnita/sectionAnita";
import SectionAboutAnterin from "views/Components/Section/sectionAboutAnterin/sectionAboutAnterin";
import SectionJalinJalanTeman from "views/Components/Section/sectionPromo/sectionJalinJalanTeman";
import SectionJalinJalanWarga from "views/Components/Section/sectionPromo/sectionJalinJalanWarga";
import SectionAtokenLinkAja from "views/Components/Section/anterinHandbook/sectionAtokenLinkAja";

var hist = createBrowserHistory();

ReactDOM.render(
  <BrowserRouter history={hist}>
    <Guide/>

    <Switch>
      <Route exact path="/" component={SectionHomeContent} />
      <Route path="/landing-page" component={LandingPage} />
      <Route path="/profile-page" component={ProfilePage} />
      <Route path="/login-page" component={LoginPage} />
      <Route path="/anterinxlinkaja" component={Cashback} />
      <Route path="/s&k-a-poin" component={SectionApoin} />
      <Route path="/berlangganan" component={sectionBerlangganan} />
      <Route path="/pembelian-atoken-lewat-linkaja" component={SectionAtokenLinkAja} />
      <Route path="/syarat-dan-ketentuan-berlangganan" component={SectionSKBerlangganan} />
      <Route path="/anterin-linkAja" component={sectionAnterinXlinkaja} />
      <Route path="/anita" component={SectionAnita} />
      <Route path="/about-anterin" component={SectionAboutAnterin} />
      <Route path="/jalin-jalan-teman" component={SectionJalinJalanTeman} />
      <Route path="/jalin-jalan-warga" component={SectionJalinJalanWarga} />


    </Switch>
    <Footer />

  </BrowserRouter>,
  document.getElementById("root")
);
