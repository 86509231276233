/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps } from "@material-ui/icons";
import LocalPlayRoundedIcon from '@material-ui/icons/LocalPlayRounded';

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Anterin Handbook"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={Apps}
          dropdownList={[
            <Link to="/berlangganan" className={classes.dropdownLink}>
              Berlangganan
            </Link>,
            <Link to="/" className={classes.dropdownLink}>
              A-Token
            </Link>,
            <Link to="/pembelian-atoken-lewat-linkaja" className={classes.dropdownLink}>
            Pembelian A-Token Memalui LinkAja
            </Link>,
            <Link to="/" className={classes.dropdownLink}>
            Pembelian A-Token Memalui Bank Transfer
            </Link>,
            <Link to="/syarat-dan-ketentuan-berlangganan" className={classes.dropdownLink}>
            Syarat Dan Ketentuan Berlangganan
            </Link>,
            <Link to="/s&k-a-poin" className={classes.dropdownLink}>
            Syarat Dan Ketentuan Penukaran A-poin
            </Link>,
            
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Anterin Promo/Campaign"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={LocalPlayRoundedIcon}
          dropdownList={[
            <Link to="/anterin-linkAja" className={classes.dropdownLink}>
              Anterin X LinkAja
            </Link>,
            <Link to="/anterinxlinkaja" className={classes.dropdownLink}>
              Cashback 20% Dengan LinkAja
            </Link>,
            <Link to="/jalin-jalan-teman" className={classes.dropdownLink}>
              JalinJalan Teman Anterin
            </Link>,
            <Link to="/jalin-jalan-warga" className={classes.dropdownLink}>
              JalinJalan Warga Anterin
            </Link>,
            <Link to="/panduan-jalin-jalan" className={classes.dropdownLink}>
              Panduan JalinJalan
            </Link>,
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/about-anterin" className={classes.navLink2}>
          <Button
            href="-"
            target="_blank"
            color="transparent"
            >
            <i class="fas fa-bell"></i>
            Tentang Anterin
          </Button>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/anita" className={classes.navLink2}>
          <Button
            href="-"
            target="_blank"
            color="transparent"
            >
            <i class="fas fa-star"></i>
            Anita (Anterin Informasi Kita)
          </Button>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/" className={classes.navLink2}>
          <Button
            href="-"
            target="_blank"
            color="transparent"
            >
            <i class="fas fa-home"></i>
            Halaman Utama
          </Button>
        </Link>
      </ListItem>
    </List>
  );
}
