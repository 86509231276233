import React from "react";



export default function SectionHome() {


    return (
      <div>
        <img style={{width:'100%'}} src={require("assets/img/banner-2.jpg")} alt="" />  
    </div>

    )

}