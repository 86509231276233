import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
// @material-ui/core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

// core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-kit-react/views/components.js";

const useStyles = makeStyles(styles);

export default function SectionJalinJalanWarga(props) {
    const classes = useStyles();
    const { ...rest } = props;

    return (
      <div>
        <Header
            brand="Anterin.Id"
            rightLinks={<HeaderLinks />}
            fixed
            color="white"
            changeColorOnScroll={{
            height: 200,
            color: "white"
            }}
            {...rest}
        />
        
        <div className={classNames(classes.main)}>
        <div className={classes.sections}>
            <div style={{fontSize:14}} className={classes.container}>
            <br/>
            <br/>
            <br/>
            <div>
                <img style={{width:'100%'}} src={require("assets/img/jalinjalan.jpg")} alt="" />  
            </div>
            <br/>
            <p><strong>JALINJALAN</strong><br /><strong>JELAJAHI &amp; TAMBAH LOKASINYA!</strong></p>
<p><strong>Tentang Program</strong><br />Melihat keterbatasan informasi mengenai lokasi-lokasi yang ada disekitar kita, serta kemudahan teknologi saat ini yang memudahkan semua orang untuk selalu&nbsp;<em>update</em>. Maka Anterin bekerja sama dengan HERE&nbsp;<em>maps</em>&nbsp;membuat suatu program JalinJalan, dimana Teman dan Warga Anterin diminta untuk melengkapi data lokasi seperti nama, alamat lengkap, nomor telepon sampai dengan foto. Setiap data lokasi yang dikumpulkan, Teman dan Warga akan mendapatkan point yang dapat ditukarkan dengan hadiah-hadiah menarik.</p>
<p>JalinJalan memberikan banyak keuntungan, antara lain :</p>
<ol>
<li>Menambahkan informasi lebih suatu lokasi, sehingga kedepannya dapat memudahkan Teman dan Warga untuk mencari lokasi</li>
<li>Bagi Teman &amp; Warga akan ada banyak hadiah untuk setiap point yang dikumpulkan</li>
<li>Untuk Warga bisa mendapatkan pendapatan tambahan</li>
</ol>
<p><strong>Poin</strong><br />Untuk setiap data/informasi yang ditambahkan, Teman dan Warga akan mendapatkan poin, sebesar:</p>
<ol>
<li>Nama lokasi : 20 poin</li>
<li>Alamat lengkap : 20 poin</li>
<li>Kategori lokasi : 30 poin</li>
<li>Foto lokasi : 80 poin</li>
</ol>
<p>Dari poin yang dikumpulkan, Warga dapat menukarnya dengan hadiah menarik, yaitu :</p>
<ul>
<ul>
<li>Bulanan : Saldo LinkAja</li>
<li>Pada akhir tahun, akumulasi poin Warga yang tidak di-redeem dan menduduki peringkat puncak dalam &ldquo;Grand Prize Leaderboard&rdquo; bisa menukarkan poinnya dengan&nbsp;<em>Grand Prize</em>&nbsp;: 2 Paket Umroh (2.400.000 A-Poin) , 1 Unit Motor&nbsp;<em>Matic&nbsp;</em>(1.500.000 A-Poin), dan hadiah hiburan</li>
</ul>
</ul>
<p>(Syarat dan Ketentuan berlaku)</p>
<p><strong>Syarat dan Ketentuan</strong><br />Ketika anda menggunakan JalinJalan, Anda mempercayai kami, PT Anterin Digital Nusantara (atau kami) dengan informasi Anda. Penting bagi kami untuk menjaga kepercayaan Anda. Karenanya, kami membutuhkan Anda untuk membaca dan menyetujui langkah-langkah yang kami terapkan dalam syarat dan ketentuan ini.</p>
<p><strong>1. Pendahuluan</strong><br />JalinJalan adalah salah satu program dari Anterin yang bekerja sama dengan HERE&nbsp;<em>maps</em>.</p>
<p><strong>2. Cakupan</strong><br />JalinJalan adalah program pencarian tempat/ POI (<em>Point of Interest</em>) melalui aplikasi, di mana pengemudi dan pengguna dapat mendaftarkan tempat dengan kategori: kios, toko, restoran, servis dan jasa, sarana pendidikan, sarana kesehatan dan hiburan</p>
<p><strong>3. Standar POI yang disetujui</strong></p>
<ul>
<li>Foto harus di depan tempat usaha dan terlihat sign/papan nama tempat usaha</li>
<li>Foto satu tempat hanya bisa diakui oleh satu contributor</li>
<li>Foto harus jelas tidak buram dan gelap</li>
<li>Alamat harus benar dan lengkap sesuai dengan nama jalan</li>
<li>Kategori harus dimasukkan sesuai dengan bentuk usaha</li>
<li>Titik harus sesuai dengan koordinat dan posisi tempat di dalam peta, kontributor bisa mempergunakan marker untuk memperjelas posisi POI.</li>
</ul>
<p><strong>4. Informasi Pribadi</strong><br />Kami tidak akan mengungkapkan informasi pribadi Anda kepada pihak manapun, kecuali untuk lembaga pemerintah, otoritas / pihak ketiga yang secara langsung memiliki kepentingan dalam pemrosesan program di bawah naungan undang &ndash;undang dan peraturan yang berlaku.</p>
<p><strong>5. Pemberhentian Penggunaan Akun</strong><br />Kami dapat menghentikan akses Anda untuk menggunakan program ini kapan saja dan tanpa pemberitahuan kepada Anda, termasuk jika (a) Anda gagal mematuhi syarat dan ketentuan ini (b) Kami mencurigai adanya penipuan, kecurangan, atau penyalahgunaan oleh Anda ; atau (c) Kami mencurigai adanya aktivitas pelanggaran hukum yang terkait dengan akun Anda.</p>
<p><strong>6. Penerimaan Data</strong><br />Anterin dan HERE berhak menerima data tempat / POI (<em>Point of Interest</em>) dari pengemudi dan pengguna program.</p>
<p><strong>7. Konektivitas</strong><br />Anda memahami dan menyetujui bahwa partisipasi dalam program ini melibatkan koneksi internet. Data internet sepenuhnya menjadi tanggung jawab pengguna.</p>
<p><strong>8. Privasi</strong><br />Program kami dirancang untuk memungkinkan Anda memfoto dan berbagi informasi tentang lokasi dari dunia nyata. Segala sesuatu yang difoto tidak boleh melanggar privasi orang lain dan peraturan pemerintah.</p>
<p><strong>9. Kepemilikan Data</strong><br />Para pihak sepakat bahwa, data atau informasi yang dikumpulkan oleh pengguna adalah milik kami, pengguna tidak berhak untuk mengklaim data.</p>
<p><strong>10. Hukum yang Berlaku</strong><br />Syarat dan Ketentuan ini akan diatur oleh hukum yang berlaku di Indonesia. Setiap dan semua perselisihan yang timbul karena penggunaan program, akan diarahkan ke yurisdiksi Pengadilan Negeri Jakarta Selatan.</p>
<p><strong>11. Hadiah (poin, redeem/penebusan, bonus)</strong><br />Ketika Anda menggunakan poin hadiah dalam program kami, Anda setuju untuk terikat oleh Syarat dan Ketentuan program ini:</p>
<ol>
<li>Warga Anterin harus melakukan minimal 10 trip done setiap bulannya agar redeem poin dapat diproses</li>
<li>Di akhir periode, (Desember 2019) poin yang tidak di-redeem akan hangus</li>
<li>Untuk grand prize, satu akun hanya bisa menukarkan 1 hadiah</li>
</ol>
<p>Keputusan Anterin terkait pemenang, hadiah dan program JalinJalan adalah mutlak dan tidak dapat diganggu gugat. Anterin berhak setiap saat dan dari waktu ke waktu, tanpa pemberitahuan, untuk menambah, menghapus, mengubah atau menghentikan program; contoh, Anterin dapat mengubah manfaat, tingkat kredit hadiah, afiliasi program, atau penawaran penukaran setiap saat tanpa pemberitahuan. Pengguna bertanggung jawab untuk mengakses situs ini untuk mendapatkan informasi tentang hak dan tanggung jawab pengguna dalam program ini.</p>
<p><strong>12. Perubahan/Pembaruan</strong><br />Anterin dapat mengubah ketentuan ini kapan saja, dan jika kami melakukannya, kami akan memberitahu Anda dengan memposting Ketentuan yang baru di aplikasi. Penting bagi Anda untuk meninjau Ketentuan apapun yang diubah sebelum Anda melanjutkan menggunakan program.</p>
<p><strong>13.&nbsp;<em>Force Majeure</em></strong><br />Layanan kami dapat terganggu oleh peristiwa atau hal-hal di luar wewenang dan kendali kami, termasuk bencana alam, gangguan listrik, gangguan telekomunikasi, kebijakan pemerintah dan hal-hal lain yang diluar wewenang atau kontrol kami. Oleh karena itu, lewat Syarat dan Ketentuan ini, Anda menyetujui untuk membebaskan kami dari klaim apapun, jika kami tidak dapat memenuhi instruksi Anda melalui akun karena&nbsp;<em>Force Majeure</em>.</p>
<p><strong>14. Hak Kekayaan Intelektual</strong><br />Semua&nbsp;<em>fitur</em>, aplikasi, program adalah milik Anterin.</p>
<p><strong>15. Tanpa Pengabaian</strong><br />Pengabaian kami atas keterlambatan atau kegagalan kami dalam mengisi klaim terhadap segala pelanggaran ketentuan dalam Syarat dan Ketentuan ini atau dalam melaksanakan hak apapun yang disediakan dalam Syarat dan Ketentuan ini tidak akan dianggap sebagai pengabaian.</p>
<p><strong>16. Panduan JalinJalan</strong><br />Untuk informasi panduan JalinJalan silahkan klik:&nbsp;<a href="https://m.anterin.id/panduan-jalinjalan/"><strong>https://m.anterin.id/panduan-jalinjalan/</strong></a></p>            <br/>
            <br/>
            <br/>
                    </div>
                </div>
            </div>
        </div>

    )

}



