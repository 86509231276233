import React from "react";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";


export default function Guide(props) {
  const { ...rest } = props;
  return (
    <div>
      <Header
        brand="Anterin.Id"
        rightLinks={<HeaderLinks />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />
    </div>
  );
}
