import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
// @material-ui/core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

// core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-kit-react/views/components.js";

const useStyles = makeStyles(styles);

export default function SectionAnterinXlinkaja(props) {
    const classes = useStyles();
    const { ...rest } = props;

    return (
      <div>
        <Header
            brand="Anterin.Id"
            rightLinks={<HeaderLinks />}
            fixed
            color="white"
            changeColorOnScroll={{
            height: 200,
            color: "white"
            }}
            {...rest}
        />
        
        <div className={classNames(classes.main)}>
        <div className={classes.sections}>
            <div style={{fontSize:14}} className={classes.container}>
            <br/>
            <br/>
            <br/>
            <div>
                <img style={{width:'100%'}} src={require("assets/img/promo.jpg")} alt="" />  
            </div>
            <br/>
        <p><strong>Tentang Program</strong></p>
<p>Saat ini Anterin bekerja sama dengan LinkAja untuk mempermudahkan Teman dan Warga untuk bertransaksi di Anterin.<br />Ada banyak keuntungan yang diperoleh, antara lain :</p>
<ul>
<li>Bisa untuk membayar orderan di Anterin</li>
<li>Bisa membeli A-Token</li>
<li>Membayar di merchant yang bekerja sama dengan LinkAja</li>
<li>Beli pulsa dan paket internet</li>
<li>Bayar tagihan telepon pasca bayar, beli token listrik, dll</li>
<li>Belanja online</li>
<li>Kirim uang tanpa perlu rekening bank</li>
</ul>
<p><strong>Syarat dan Ketentuan</strong></p>
<ul>
<li>Reward Paket Data 500MB (berlaku 3 hari) untuk transaksi menggunakan LinkAja di aplikasi Anterin</li>
<li>Reward Paket Data 1GB (berlaku 3 hari) untuk setiap 10 kali transaksi menggunakan LinkAja di aplikasi Anterin</li>
<li>Reward paket data hanya berlaku untuk pelanggan Telkomsel (prepaid)</li>
<li>Promo berlaku dengan minimum transaksi Rp 3.000,-</li>
<li>Promo berlaku maksimum 1 transaksi / pelanggan / minggu</li>
<li>Periode promo 15 Januari &ndash; 31 Desember 2019</li>
<li>Promo ini tidak dapat digabungkan dengan promo lainnya</li>
<li>Promo dapat berubah sewaktu-waktu tanpa pemberitahuan terlebih dahulu</li>
</ul>
            <br/>
            <br/>
            <br/>
                    </div>
                </div>
            </div>
        </div>

    )

}



