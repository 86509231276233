import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
// @material-ui/core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

// core components
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import styles from "assets/jss/material-kit-react/views/components.js";

// const useStyles = makeStyles(styles);
const useStyles = makeStyles(styles, theme => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
  }));
  
export default function SectionBerlangganan(props) {
    const classes = useStyles();
    const { ...rest } = props;
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
      <div>
        <Header
            brand="Anterin.Id"
            rightLinks={<HeaderLinks />}
            fixed
            color="white"
            changeColorOnScroll={{
            height: 200,
            color: "white"
            }}
            {...rest}
        />
        
        <div className={classNames(classes.main)}>
        <div className={classes.sections}>
            <div style={{fontSize:14}} className={classes.container}>
            <br/>
        <br/>
        <br/>
        <br/>
        <div className={classes.root}>
            <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                >
                <Typography className={classes.heading}>Apa Itu BERLANGGANAN?</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                <Typography>
                    Merupakan suatu sistem langganan yang berlaku di aplikasi Anterin yang dapat digunakan oleh Warga Anterin, di mana untuk menggunakan sistem ini wajib menggunakan A-Token.
                </Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
                >
                <Typography className={classes.heading}>Bagaimana caranya BERLANGGANAN?</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                <Typography>
                    Untuk dapat Berlangganan di Anterin, Warga Anterin wajib memiliki A-Token.
                </Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
                >
                <Typography className={classes.heading}>Apa Perbedaaan Berlangganan (Subscription) Dengan yang Lain? </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                <Typography>
                    Berlangganan (Subscription) Anterin tidak memotong penghasilan dan tidak mengambil pendapatan Warga, sehingga Warga akan mendapatkan seluruh penghasilannya.
                </Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
                >
                <Typography className={classes.heading}>Apa Keuntungan Berlangganan di Anterin?</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                <Typography>
                Untuk Warga Anterin
                <br/>1. Kepastian order. Teman Anterin lebih yakin akan mendapatkan Warga Anterin yang online.
                <br/>2. Lebih murah. Dibandingkan dengan potongan komisi harian, Berlangganan di Anterin cukup bayar sekali Warga Anterin bebas Anterin berapapun selama seharian penuh.
                <br/>3. Berhak mengikuti seluruh program exclusive Anterin

                </Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel5bh-header"
                >
                <Typography className={classes.heading}>Ada Promo Berlangganan?</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                <Typography>
                Promo bundling masa aktif token Anterin motor
                <ul style={{paddingLeft:0}}>
                    <li>
                        Top up Rp75.000, untuk A-Token 70.000 dengan masa aktif A-Token 7 hari + Gratis 7 hari
                    </li>
                    <li>
                        Top up Rp150.000, untuk A-Token 140.000 dengan masa aktif A-Token 14 hari + Gratis 16 hari
                    </li>
                    <br/>
                    <p style={{fontSize:16}}>
                        Promo bundling masa aktif token Anterin mobil
                    </p>
                    <li>
                        Top up Rp75.000, untuk A-Token 70.000 dengan masa aktif A-Token 4 hari + Gratis 10 hari
                    </li>
                    <li>
                        Top up Rp150.000, untuk A-Token 140.000 dengan masa aktif A-Token 7 hari + Gratis 23 hari
                    </li>
                </ul>
                *syarat dan ketentuan berlaku
                </Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            </div>
            <br/>
            <br/>
            <br/>
                    </div>
                </div>
            </div>
        </div>

    )

}



