import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

// core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-kit-react/views/components.js";

const useStyles = makeStyles(styles);

export default function SectionDiscount(props) {
    const classes = useStyles();
    const { ...rest } = props;

    return (
      <div>
        <Header
            brand="Anterin.Id"
            rightLinks={<HeaderLinks />}
            fixed
            color="white"
            changeColorOnScroll={{
            height: 200,
            color: "white"
            }}
            {...rest}
        />
        
        <div className={classNames(classes.main)}>
        <div className={classes.sections}>
            <div style={{fontSize:14}} className={classes.container}>
            <br/>
        <br/>
        <br/>
        <br/>
            <h4><strong>Cashback 20% Dengan LinkAja</strong></h4>
            <p>Saat ini Anterin bekerja sama dengan LinkAja untuk mempermudahkan Teman dan Warga untuk bertransaksi di Anterin.<br />Ada banyak keuntungan yang diperoleh, antara lain :</p>
                <ul >
                <li >Bisa untuk membayar orderan di Anterin</li>
                <li >Bisa membeli A-Token</li>
                <li >Membayar di merchant yang bekerja sama dengan LinkAja</li>
                <li >Beli pulsa dan paket internet</li>
                <li >Bayar tagihan telepon pasca bayar, beli token listrik, dll</li>
                <li >Belanja online</li>
                <li >Kirim uang tanpa perlu rekening bank</li>
                </ul>
                <p >Syarat &amp; Ketentuan Anterin LinkAja</p>
                <p >1. Cashback 20% untuk transaksi menggunakan LinkAja di aplikasi ANTERIN.</p>
                <p >2. Maksimum cashback yang diberikan adalah Rp. 5.000,-</p>
                <p >3. Cashback akan masuk secara otomatis ke dalam Saldo Bonus.</p>
                <p >4. Maksimum 5x cashback/user/merchant selama periode promo.</p>
                <p >5. Promo ini tidak dapat digabungkan dengan promo lainnya.</p>
                <p >6. Promo dapat berubah sewaktu-waktu tanpa pemberitahuan terlebih dahulu.</p>
                <br/>
                <br/>
                <br/>
                <br/>
                    </div>
                </div>
            </div>
        </div>

    )

}